<template>
  <v-container>
    <v-card width="400" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1">Login</h1>
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            label="Username"
            prepend-icon="mdi-account-circle"
            v-model="username"
          />
          <v-text-field
            v-model="password"
            :type="passwordType"
            label="Password"
            prepend-icon="mdi-lock"
            :append-icon="passwordIcon"
            @click:append="passwordToggle()"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn @click="valifyLogin">Login</v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import AxiosEvents from '@/services/AxiosEvents.js'

export default {
  name: 'Login',
  data: () => ({
    username: '',
    password: '',
    showPassword: false,
  }),
  computed: {
    passwordType() {
      return this.showPassword ? 'text' : 'password'
    },
    isLogin() {
      return this.$store.state.isLogin
    },
    passwordIcon() {
      return this.showPassword ? 'mdi-eye' : 'mdi-eye-off'
    },
  },
  watch: {
    token(newToken) {
      localStorage.token = newToken
    },
  },
  methods: {
    passwordToggle() {
      this.showPassword = !this.showPassword
    },
    async valifyLogin() {
      try {
        const token = await AxiosEvents.postLoinUserData({
          username: this.username,
          password: this.password,
        })
        var gid = localStorage.getItem('groupId')
        if (gid === null) {
          gid = 'new'
        }
        this.$store.dispatch('login', {
          token: token.data,
          groupId: gid,
        })
        this.$router.push({
          name: 'top',
          params: {
            floor: '5F',
          },
        })
      } catch (err) {
        console.log(err)
      }
    },
    mounted() {},
  },
}
</script>
